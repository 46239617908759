import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { crearEntradaArrendamiento, editarEntradaArrendamiento } from '../api/arrendamientoAPI';

const FormArrendamiento = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const entryToEdit = location.state?.entry;

  const [formData, setFormData] = useState({
    category: '',
    period: '',
    rate: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (entryToEdit) {
      setFormData({
        category: entryToEdit.Category || '',
        period: entryToEdit.Period || '',
        rate: entryToEdit.Rate || '',
      });
    }
  }, [entryToEdit]);

  // Manejar cambios en los inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (
      !formData.category.trim() || 
      formData.period === '' || 
      formData.rate === ''
    ) {
      setError('Todos los campos son obligatorios.');
      return;
    }

    try {
      if (entryToEdit) {
        // Editar entrada
        await editarEntradaArrendamiento(entryToEdit.ID, {
          period: parseInt(formData.period),
          rate: parseFloat(formData.rate),
        });
        alert('Entrada editada con éxito.');
      } else {
        // Crear nueva entrada
        await crearEntradaArrendamiento(formData);
        alert('Entrada añadida con éxito.');
      }
      navigate('/arrendamiento'); // Redirigir a la vista de arrendamiento
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h1>{entryToEdit ? 'Editar Entrada de Arrendamiento' : 'Crear Nueva Entrada de Arrendamiento'}</h1>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Categoría:
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              required
              disabled={!!entryToEdit} // Campo bloqueado si es para editar
            >
              <option value="" disabled>
                Selecciona una categoría
              </option>
              <option value="Paneles solares">Paneles solares</option>
              <option value="Maquinaria Industrial">Maquinaria Industrial</option>
              <option value="Maquinaria Agrícola">Maquinaria Agrícola</option>
              <option value="Equipo de oficina">Equipo de oficina</option>
              <option value="Equipo médico">Equipo médico</option>
              <option value="Otros">Otros</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Periodo (en meses):
            <input
              type="number"
              name="period"
              value={formData.period}
              onChange={handleInputChange}
              placeholder="Escribe el periodo"
              required
            />
          </label>
        </div>
        <div>
          <label>
            Tasa (en formato decimal, ej. 0.24):
            <input
              type="number"
              step="0.01"
              name="rate"
              value={formData.rate}
              onChange={handleInputChange}
              placeholder="Escribe la tasa"
              required
            />
          </label>
        </div>
        <div style={{ marginTop: '10px' }}>
          <button type="submit" style={{ marginRight: '10px' }}>
            {entryToEdit ? 'Guardar Cambios' : 'Añadir'}
          </button>
          <button
            type="button"
            onClick={() => navigate('/arrendamiento')}
            style={{ backgroundColor: 'gray', color: 'white' }}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormArrendamiento;
