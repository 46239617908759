// Metodo GET
export const obtenerTasasArrendamiento = async () => {
    try {
      const response = await fetch('https://makoservicio.vulcanics.mx/api/leasing-rates', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
  
      const result = await response.json();
  
      if (!result || !result.data || !result.data.length) {
        throw new Error('No se encontraron tasas de arrendamiento.');
      }
  
      return result.data; // Retornamos el array de tasas
    } catch (error) {
      console.error('Error al obtener tasas de arrendamiento:', error.message);
      throw error;
    }
  };

//Metodo PUT

  export const crearEntradaArrendamiento = async (entrada) => {
    try {
      const response = await fetch('https://makoservicio.vulcanics.mx/api/leasing-rates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(entrada), // Enviar datos en formato JSON
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud POST: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.message || 'Error al crear la entrada.');
      }
  
      console.log('Entrada creada con éxito:', data);
      return data; // Devolver la respuesta de la API
    } catch (error) {
      console.error('Error al crear la entrada de arrendamiento:', error.message);
      throw error;
    }
  };

//PUT o EDITAR

  export const editarEntradaArrendamiento = async (id, entrada) => {
    try {
      const response = await fetch(`https://makoservicio.vulcanics.mx/api/leasing-rates?id=${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(entrada),
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud PUT: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.error || 'Error al actualizar la entrada.');
      }
  
      console.log('Entrada actualizada con éxito:', data);
      return data;
    } catch (error) {
      console.error('Error al actualizar la entrada de arrendamiento:', error.message);
      throw error;
    }
  };
  

//DELETE 

  export const eliminarEntradaArrendamiento = async (id) => {
    try {
      const response = await fetch(`https://makoservicio.vulcanics.mx/api/leasing-rates?id=${id}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud DELETE: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.error || 'Error al eliminar la entrada.');
      }
  
      console.log('Entrada eliminada con éxito:', data);
      return data;
    } catch (error) {
      console.error('Error al eliminar la entrada de arrendamiento:', error.message);
      throw error;
    }
  };
  
  
  