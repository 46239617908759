import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  obtenerTasasArrendamiento,
  eliminarEntradaArrendamiento,
} from '../api/arrendamientoAPI';

const Arrendamiento = () => {
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({ id: '', category: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerTasasArrendamiento();
        setEntries(data);
        setFilteredEntries(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  // Manejar cambios en los filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });

    let filtered = entries;

    if (name === 'id' && value.trim()) {
      filtered = filtered.filter((entry) =>
        entry.ID.toString().includes(value.trim())
      );
    }

    if (name === 'category' && value.trim()) {
      filtered = filtered.filter((entry) =>
        entry.Category.toLowerCase().includes(value.trim().toLowerCase())
      );
    }

    setFilteredEntries(filtered);
  };

  // Función para manejar la edición
  const handleEdit = (entry) => {
    navigate('/form-arrendamiento', { state: { entry } });
  };

  // Función para manejar la eliminación
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      `¿Estás seguro de eliminar la entrada con ID ${id}?`
    );
    if (confirmDelete) {
      try {
        await eliminarEntradaArrendamiento(id);
        alert(`Entrada con ID ${id} eliminada con éxito.`);
        setEntries((prevEntries) =>
          prevEntries.filter((entry) => entry.ID !== id)
        );
        setFilteredEntries((prevEntries) =>
          prevEntries.filter((entry) => entry.ID !== id)
        );
      } catch (error) {
        alert(`Error al eliminar la entrada: ${error.message}`);
      }
    }
  };

  return (
    <div>
      <h1>Vista de Arrendamiento</h1>
      <button onClick={() => navigate('/inicio')}>Volver</button>
      <button
        onClick={() => navigate('/form-arrendamiento')}
        style={{ marginBottom: '20px', marginLeft: '10px' }}
      >
        Crear Nueva Entrada
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Filtros */}
      <div style={{ marginTop: '20px' }}>
        <label>
          Buscar por ID:
          <input
            type="text"
            name="id"
            value={filters.id}
            onChange={handleFilterChange}
            placeholder="Escribe el ID"
            style={{ marginLeft: '10px', marginRight: '20px' }}
          />
        </label>
        <label>
          Buscar por Categoría:
          <input
            type="text"
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
            placeholder="Escribe la categoría"
            style={{ marginLeft: '10px' }}
          />
        </label>
      </div>

      {/* Tabla */}
      <table
        border="1"
        style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}
      >
        <thead>
          <tr>
            <th style={{ width: '5%' }}>ID</th>
            <th style={{ width: '50%' }}>Categoría</th>
            <th style={{ width: '15%' }}>Periodo</th>
            <th style={{ width: '10%' }}>Tasa</th>
            <th style={{ width: '20%' }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredEntries.map((entry) => (
            <tr key={entry.ID}>
              <td>{entry.ID}</td>
              <td>{entry.Category}</td>
              <td>{entry.Period} meses</td>
              <td>{parseFloat(entry.Rate) * 100}%</td>
              <td>
              <button
  onClick={() => handleEdit(entry)}
  style={{
    marginRight: '5px',
    padding: '3px 8px',
    fontSize: '10px',
  }}
>
  Editar
</button>

                <button
                  onClick={() => handleDelete(entry.ID)}
                  style={{
                    color: 'red',
                    padding: '3px 8px',
                    fontSize: '10px',
                  }}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Arrendamiento;
