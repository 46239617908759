import React from 'react';
import { useNavigate } from 'react-router-dom';

const Inicio = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
  };

  const handleDictionary = () => {
    navigate('/diccionario');
  };

  const handleArrendamiento = () => {
    navigate('/arrendamiento'); // Navegación a la nueva vista de Arrendamiento
  };

  return (
    <div>
      <h1>Vista de Inicio</h1>
      <button onClick={handleDictionary}>Diccionario</button>
      <button onClick={handleArrendamiento}>Arrendamiento</button> {/* Nuevo botón */}
      <button onClick={handleLogout}>Cerrar sesión</button>
    </div>
  );
};

export default Inicio;
